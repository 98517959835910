import api from "@/api";
import { ListOpions } from "@/models/type";
import { Options, Vue } from "vue-class-component";
import './index.scss'
import dropdown from './components/dropdown.vue'
import { RouterView } from "vue-router";

@Options({
  components: { dropdown }
})
export default class Sanchuang extends Vue {

  public searchObj: any = {
    keyword: ''
  }
  public typeName: string | number = 'all'
  public activeBool = false

  public categoryTreeList: Array<ListOpions> = []

  public total = 0
  public pageNum = 1
  public pageSize = 12

  public tableData: Array<any> = [
    {
      name:'中式線裝書手作（廿四節氣）',
      id: 56,
      coverImg: '/img/sanchuang/4.jpg',
      price: 20
    },
    {
      name:'中式線裝書手作（節日）',
      id: 57,
      coverImg: '/img/sanchuang/1.jpg',
      price: 20
    },
    {
      name:'香囊手作',
      id: 58,
      coverImg: '/img/sanchuang/3.jpg',
      price: 20
    },
    {
      name:'團扇手作',
      id: 60,
      coverImg: '/img/sanchuang/2.jpg',
      price: 30
    },
  ]

  getData(): void {
    Promise.all([
      api.categoryTreeList()
    ]).then(res => {
      // 商品类型列表
      const defaultArr = [{ name: '全部', value: 'all' }]
      this.categoryTreeList = defaultArr.concat(res[0].data.map((item: any) => ({ name: item.name, value: item.id })))
    })
  }

  headerDom(): JSX.Element {
    const slot = {
      suffix: () => <div
        class="el-icon-search pointer"
        style={{
          padding: '0 10px'
        }}
        onClick={this.getList}
      />
    }
    return <div class="sanchuang-header">
      {/* <el-row gutter={60}>
        <el-col xs={24} sm={24} md={24} lg={12} xl={12}>
          <el-input
            class="sanchuang-header-cell"
            v-model={this.searchObj.keyword}
            placeholder="请输入关键词搜索"
            v-slots={ slot }
          />
        </el-col>
        <el-col xs={24} sm={24} md={24} lg={12} xl={12}>
          <div class="sanchuang-header-cell sanchuang-header-type">
            <div class="cell-shop" onClick={this.toMayShop}>
              <i class="el-icon-shopping-cart-2" />
              <span>我的购物车</span>
            </div>
            <div>
              <div class="cell-type" >
                {this.categoryTreeList.slice(0, 3).map(
                  item => <div
                    class={['cell-type-show', this.typeName === item.value ? 'cell-type-active' : '']}
                    onClick={ () => this.typeChange(item.value) }
                  >
                    {item.name}
                  </div>
                )}
                <dropdown
                  statusList={this.categoryTreeList.slice(3)}
                  onCommand={ this.onCommand }
                  activeBool={this.activeBool}
                />
              </div>
            </div>
          </div>
        </el-col>
      </el-row> */}
    </div>
  }

  /**
   * @description 点击下拉框返回的数据
   * @author Jinx
   * @date 2021-10-25 15:29:47
   * @param {(string | number)} value
   * @memberof Sanchuang
   */
  onCommand(value: string | number, e: Event): void {
    if(e) {
      this.activeBool = true
      this.typeName = value
      this.getList()
    }
  }

  toMayShop(): void {
    // 我的购物车
    this.$router.push({
      name: 'ShopCard'
    })
  }

  typeChange(value: string | number): void {
    this.activeBool = false
    this.typeName = value
    this.getList()
  }

  getList(): void {
    const productCategoryId = this.typeName === 'all' ? '' : this.typeName
    api.productSearch({
      pageNum: this.pageNum,
      pageSize: this.pageSize,
      productCategoryId,
      ...this.searchObj,
      type: 0
    }).then(res => {
      // this.tableData = res.data.list
      this.pageNum = res.data.pageNum
      this.pageSize = res.data.pageSize
      this.total = res.data.total
    })
  }

  listDom(): JSX.Element {
    return <el-row  gutter={60}>
      {this.tableData.map(item => <el-col span={12}>
        <div class="sanchuang-product"
        // style={{backgroundImage: 'url()',backgroundSize: '100% 150%',backgroundRepeat: 'no-repeat', opacity:0.7, position: 'relative'}}
        style={{position: 'relative'}}
          onClick={() => this.goDetail(item.id)}
        >
          <img src="/img/sanchuang/bg.jpg" alt=""  style={{width: '100%', height: '100%',objectFit:'cover',opacity:0.6, position: 'absolute', top: 0, left:0, zIndex: 0}}/>
          <div style={{position: 'relative', zIndex: 1}}>
            <img class="sanchuang-product-img" src={item.coverImg} alt={item.name}  style={{}}/>
            {/* <div class="sanchuang-product-img" style={{backgroundImage:`url(${item.coverImg})`, backgroundSize: '100% 100%',backgroundRepeat: 'no-repeat'}}></div> */}

            <div class="sanchuang-product-title">{item.name}</div>
            <div class="sanchuang-product-bottom">
              <div style={{fontSize: '20px', color: 'black'}}>HK$ <span>{item.price}</span></div>
              <div></div>
              {/* <div>评论: <span>{ item.commentNum }</span></div> */}
            </div>
          </div>
        </div>
      </el-col>)}
    </el-row>
  }

  goDetail(id: number): void {
    this.$router.push({
      name: 'SanchuangDetail',
      params: { id },
      query: {
        type: 0
      }
    })
  }

  handleCurrentChange(pageNum: number): void {
    this.pageNum = pageNum
    this.getList()
  }

  created(): void {
    this.getData()
  }

  render(): JSX.Element {
    return this.$route.path === '/sanchuang'
     ? <div class="sanchuang">
        {this.headerDom()}
        {this.listDom()}
        {/* 隐藏指示器，分页用的 */}
        {/* <el-pagination
          class="sanchuang-pagination"
          onCurrentChange={this.handleCurrentChange}
          currentPage={this.pageNum}
          pageSize={this.pageSize}
          layout="prev, pager, next, total"
          total={ this.total }
        /> */}
      </div>
      : <RouterView />
  }

  mounted(): void {
    this.getList()
  }
}
